import axios from "axios";
import { useEffect, useState } from "react";

interface Student {
  _id: string;
  name: string;
  grade: number;
}

function App() {
  const [students, setStudents] = useState<Student[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data: students } = await axios.get<Student[]>("/api/students");
        setStudents(students);
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  return (
    <div className="App">
      <h2>Students</h2>
      <ul>
        {loading ? 'Loading...' : students.map((student) => (
          <li key={student._id}>{student.name}</li>
        ))}
      </ul>
    </div>
  );
}

export default App;
